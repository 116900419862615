import React, { FC, useState } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import Intro from './Intro';
import { Answers, QuizStatus } from '../types';
import { Quiz } from '@wix/ambassador-quiz-v1-quiz/types';
import { Question as QuestionType } from '@wix/ambassador-quiz-v1-question/types';
import Question from './Question';

export type ControllerProps = {
  quiz: Quiz;
  questions: QuestionType[];
};

const Widget: FC<WidgetProps<ControllerProps>> = ({ quiz, questions }) => {
  const [status, setStatus] = useState<QuizStatus>(QuizStatus.intro);
  const [answers, setAnswers]: [Answers, Function] = useState({});
  const [quizEndDate, setQuizEndDate] = useState<number>();
  const [startDate, setStartDate] = useState<number>(0);
  const [timeSpent, setTimeSpent] = useState<number>(0);

  const isMobile = false;
  const isRtl = false;
  const isSeo = false;

  const onStartQuiz = () => {
    setStartDate(Date.now());
    setStatus(QuizStatus.question);
    if (quiz.test?.timeLimit) {
      setQuizEndDate(Date.now() + quiz.test.timeLimit * 1000);
    }
  };
  const onFinishQuiz = () => {
    setTimeSpent(Math.round((Date.now() - startDate) / 1000));
    setStatus(QuizStatus.result);
    setQuizEndDate(0);
  };
  const onResetQuiz = () => setStatus(QuizStatus.intro);

  return (
    <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRtl, seo: isSeo }}>
      {status === QuizStatus.question ? (
        <Question
          quiz={quiz}
          questions={questions}
          onFinishQuiz={onFinishQuiz}
          onResetQuiz={onResetQuiz}
          answers={answers}
          setAnswers={setAnswers}
          quizEndDate={quizEndDate}
        />
      ) : (
        <Intro
          quiz={quiz}
          onStartQuiz={onStartQuiz}
          questions={questions}
          status={status}
          answers={answers}
          timeSpent={`${Math.floor(timeSpent / 60)}:${String(
            timeSpent % 60,
          ).padStart(2, '0')}min`}
        />
      )}
    </TPAComponentsProvider>
  );
};

export default Widget;

// frame 1284px
// left column 775px
// text block 487px centered horizontaly and vertically
// 153 + 487 + 98

// right 510

// 60 / 40
