import React, { FC, useState } from 'react';
import { st, classes } from './Question.st.css';
import { Quiz } from '@wix/ambassador-quiz-v1-quiz/types';
import { Question as QuestionType } from '@wix/ambassador-quiz-v1-question/types';
import {
  Button,
  Divider,
  Text,
  TextTypography,
  Pagination,
  Checkbox,
  CheckboxTheme,
  ButtonPriority,
} from 'wix-ui-tpa';
import Countdown from 'react-countdown';

export type ControllerProps = {
  quiz: Quiz;
  onFinishQuiz: Function;
  onResetQuiz: Function;
  questions: QuestionType[];
  answers: { [key: string]: number };
  setAnswers: Function;
  quizEndDate: number | undefined;
};

const Question: FC<ControllerProps> = ({
  quiz,
  questions,
  onFinishQuiz,
  answers,
  setAnswers,
  quizEndDate,
}) => {
  const [questionNumber, setQuestionNumber] = useState(0);

  const gotoQuestion = (newQuestionNumber: number) => {
    if (newQuestionNumber >= 0 && newQuestionNumber < questions.length) {
      setQuestionNumber(newQuestionNumber);
    }
  };

  const question = questions[questionNumber];
  const questionId = question.id as string;
  const options =
    question.singleSelect?.options || question.multiSelect?.options || [];

  const toggleOption = (option: number) => {
    if (answers[questionId] === option) {
      setAnswers({ ...answers, [questionId]: undefined });
    } else {
      setAnswers({ ...answers, [questionId]: option });
    }
  };

  const countdownRenderer = ({
    hours,
    minutes,
    seconds,
    completed,
  }: {
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      // Render a completed state
      onFinishQuiz();
      return null;
    } else {
      // Render a countdown
      return (
        <span>
          {hours ? `${hours <= 9 ? `0${hours}` : hours}:` : null}
          {minutes <= 9 ? `0${minutes}` : minutes}:
          {seconds <= 9 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  return (
    <div className={classes.question}>
      <div>
        <div className={st(classes.header, classes.wrap, classes.grid)}>
          <Text typography={TextTypography.largeTitle} tagName="h1">
            {quiz.title}
          </Text>
          <div className={classes.timeLeftContainer}>
            {quizEndDate ? (
              <Text
                typography={TextTypography.runningText}
                className={classes.timeLeft}
              >
                Time:{' '}
                <Countdown renderer={countdownRenderer} date={quizEndDate} />
              </Text>
            ) : null}
            <Button
              onClick={() => onFinishQuiz()}
              className={classes.finishButton}
              upgrade
              priority={ButtonPriority.primary}
            >
              Finish Quiz
            </Button>
          </div>
        </div>
        <Divider className={st(classes.hr, classes.divider)} />
      </div>
      <div
        className={classes.questionMain}
        style={{ backgroundImage: `url(${question.image?.url})` }}
      >
        <div className={st(classes.text, classes.wrap, classes.grid)}>
          <div>
            <div className={classes.questionText}>
              <Text typography={TextTypography.largeTitle} tagName="h2">
                {questionNumber < 9 ? '0' : ''}
                {questionNumber + 1}.
              </Text>
              <Text typography={TextTypography.runningText}>
                {question.title}
              </Text>
            </div>
            <div className={classes.options}>
              <Text
                typography={TextTypography.runningText}
                className={classes.answers}
              >
                Answers
              </Text>
              {options.map((option, index) => (
                <Checkbox
                  key={`${question.id}-${index}`}
                  theme={CheckboxTheme.Box}
                  name={`questions-${questionId}`}
                  checked={answers[questionId] === index}
                  label={option.title}
                  onChange={() => toggleOption(index)}
                  className={classes.checkbox}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Divider className={classes.divider} />
        <div className={st(classes.footer, classes.wrap)}>
          <Button
            onClick={() => gotoQuestion(questionNumber - 1)}
            disabled={questionNumber === 0}
          >
            Previous Question
          </Button>
          <div className={classes.pagination}>
            <Pagination
              currentPage={questionNumber + 1}
              totalPages={questions.length}
              onChange={({ page }) => gotoQuestion(page - 1)}
            />
          </div>
          <Button
            onClick={() => gotoQuestion(questionNumber + 1)}
            disabled={questionNumber === questions.length - 1}
          >
            Next Question
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Question;
