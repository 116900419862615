import React, { FC } from 'react';
import { classes, st } from './Intro.st.css';
import {
  Button,
  ButtonPriority,
  Text,
  TextButton,
  TextTypography,
} from 'wix-ui-tpa';
import ChevronLeftSmall from 'wix-ui-icons-common/on-stage/ChevronLeftSmall';
import { Quiz } from '@wix/ambassador-quiz-v1-quiz/types';
import { Question } from '@wix/ambassador-quiz-v1-question/types';
import { Answers, QuizStatus } from '../../types';

export type ControllerProps = {
  quiz: Quiz;
  onStartQuiz: Function;
  questions: Question[];
  status: QuizStatus;
  answers: Answers;
  timeSpent?: any;
};

const Intro: FC<ControllerProps> = ({
  quiz,
  onStartQuiz,
  questions,
  status,
  answers,
  timeSpent,
}) => {
  let timeToPass = '';
  if (quiz.test?.timeLimit) {
    const minutes = Math.floor(quiz.test.timeLimit / 60);
    const seconds = quiz.test?.timeLimit - minutes * 60;
    timeToPass = `${minutes <= 9 ? `0${minutes}` : minutes}:${
      seconds <= 9 ? `0${seconds}` : seconds
    }`;
  }

  const correctAnswers = questions.reduce((acc, cur) => {
    const options = (cur.singleSelect || cur.multiSelect)?.options || [];
    if (
      options.findIndex((o) => o.isCorrect === true) ===
      answers[cur.id as string]
    ) {
      acc++;
    }
    return acc;
  }, 0);

  let success = true;
  if (quiz.test?.passingThreshold) {
    success =
      Math.ceil((correctAnswers / questions.length) * 100) >=
      quiz.test.passingThreshold;
  }
  // const onReviewAnswers = () => {};

  return (
    <div
      className={classes.fullPage}
      style={{ backgroundImage: `url(${quiz.image?.url})` }}
    >
      <div className={classes.wrapper}>
        <div className={classes.leftWrapped}>
          <a href="/quizzes">
            <TextButton prefixIcon={<ChevronLeftSmall />}>
              Back to list
            </TextButton>
          </a>
          <div className={classes.info}>
            {status === QuizStatus.intro ? (
              <>
                <Text
                  typography={TextTypography.largeTitle}
                  className={classes.title}
                >
                  {quiz.title}
                </Text>
                <Text
                  typography={TextTypography.runningText}
                  className={classes.description}
                >
                  {quiz.description}
                </Text>
                <Text
                  typography={TextTypography.runningText}
                  className={classes.timeToPass}
                >
                  Time to pass: {timeToPass}
                </Text>
                <Text
                  typography={TextTypography.runningText}
                  className={classes.questions}
                >
                  Questions: {questions.length}
                </Text>
                <Button
                  onClick={() => onStartQuiz()}
                  className={classes.button}
                  upgrade
                  priority={ButtonPriority.primary}
                >
                  Start Quiz
                </Button>
              </>
            ) : null}
            {status === QuizStatus.result ? (
              <>
                <Text
                  typography={TextTypography.runningText}
                  className={classes.resultTitle}
                >
                  {quiz.title}
                </Text>
                <Text
                  typography={TextTypography.largeTitle}
                  className={classes.resultMessage}
                >
                  {success
                    ? 'You Passed, Hurray!'
                    : "Sorry, you didn't pass :("}
                </Text>
                <Text
                  typography={TextTypography.runningText}
                  className={classes.correctAnswers}
                >
                  Correct answers: {correctAnswers}/{questions.length}
                </Text>
                <Text
                  typography={TextTypography.runningText}
                  className={classes.timeSpent}
                >
                  Time spent: {timeSpent}
                </Text>

                {/* <Button
                  onClick={() => onReviewAnswers()}
                  className={classes.button}
                  upgrade
                  priority={ButtonPriority.primary}
                >
                  Review Answers
                </Button> */}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
